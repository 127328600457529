<template>
    <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false" style="z-index: 9999;">
        <md-content>
            <div class="page-content" style="min-height: 550px; max-height: 90vh;">
                <div class="grid">
                    <div class="row no-gutters page-list-header">
                        <div class="col l-6 m-6 c-12">
                            <h4 class="page-list-title"><md-icon>note_add</md-icon> <span>{{title}}</span></h4>
                        </div>
                        <div class="col l-6 m-6 c-12">
                            <div class="page-list-action"> 
                                <md-button @click="close()" class="md-raised">Đóng<md-tooltip>Đóng</md-tooltip></md-button>
                            </div>
                        </div>
                    </div>
                    <div class="page-list-body">
                        <div class="form-body form-tab">
                            <div class="row">
                                <div class="col l-12 m-12 c-12">
                                    <md-tabs>
                                        <md-tab id="tab-file-order-detail" class="tab-content" md-label="File đính kèm cont/kiện hàng">
                                            <table class="data-table" style="padding-top: 10px;">
                                                <thead>
                                                    <tr>
                                                        <th style="width: 80px;">#</th>
                                                        <th style="width: 140px;">Ảnh</th>
                                                        <th style="width: calc(100vw - 220px);">Tên file</th>
                                                        <th style="width: 15%;">Ngày tạo</th>
                                                    </tr>
                                                </thead>
                                                <tbody v-if="files.length == 0" style="height: 150px;">
                                                    <tr><td colspan="100" class="no-data"><span>Không có file</span></td></tr>
                                                </tbody>
                                                <tbody>
                                                    <tr v-for="(item, index) in files" :key="item.id" :class="{ 'odd': index % 2 !== 0 }">
                                                        <td class="center">{{index + 1}}</td>
                                                        <td class="center">
                                                            <a @click="view(item)">
                                                                <img v-if="item.fileExt == 'pdf' || item.fileExt == 'PDF'" class="box-img" src="../../../assets/images/pdf.svg" />
                                                                <img v-else-if="item.fileExt == 'xlsx' || item.fileExt == 'XLSX'" class="box-img" src="../../../assets/images/excel.svg" />
                                                                <img v-else-if="item.fileExt == 'docx' || item.fileExt == 'DOCX'" class="box-img" src="../../../assets/images/word.svg" />
                                                                <img v-else class="box-img" :src="item.fullPath" />
                                                            </a>
                                                        </td>
                                                        <td class="left"><a @click="view(item)">{{item.fileName}}</a></td>
                                                        <td class="center">{{item.createdTime}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </md-tab>
                                        <md-tab id="tab-file-order" class="tab-content" md-label="File đính kèm đơn hàng">
                                            <table class="data-table" style="padding-top: 10px;">
                                                <thead>
                                                    <tr>
                                                        <th style="width: 80px;">#</th>
                                                        <th style="width: 140px;">Ảnh</th>
                                                        <th style="width: calc(100vw - 220px);">Tên file</th>
                                                        <th style="width: 15%;">Ngày tạo</th>
                                                    </tr>
                                                </thead>
                                                <tbody v-if="orderFiles.length == 0" style="height: 150px;">
                                                    <tr><td colspan="100" class="no-data"><span>Không có file</span></td></tr>
                                                </tbody>
                                                <tbody>
                                                    <tr v-for="(item, index) in orderFiles" :key="item.id" :class="{ 'odd': index % 2 !== 0 }">
                                                        <td class="center">{{index + 1}}</td>
                                                        <td class="center">
                                                            <a @click="view(item)">
                                                                <img v-if="item.fileExt == 'pdf' || item.fileExt == 'PDF'" class="box-img" src="../../../assets/images/pdf.svg" />
                                                                <img v-else-if="item.fileExt == 'xlsx' || item.fileExt == 'XLSX'" class="box-img" src="../../../assets/images/excel.svg" />
                                                                <img v-else-if="item.fileExt == 'docx' || item.fileExt == 'DOCX'" class="box-img" src="../../../assets/images/word.svg" />
                                                                <img v-else class="box-img" :src="item.fullPath" />
                                                            </a>
                                                        </td>
                                                        <td class="left"><a @click="view(item)">{{item.fileName}}</a></td>
                                                        <td class="center">{{item.createdTime}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </md-tab>
                                    </md-tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </md-content>
    </md-dialog>
</template>

<script>
    import orderService from '../../../api/orderService';
    import messageBox from '../../../utils/messageBox';
    import { mapActions } from 'vuex';

    export default ({
        data() {
            return {
                showDialog: false,
                obj: { },
                files: [],
                orderFiles: [],
                title: '',
                orderId: 0,
                orderDetailId: 0,
                serial: ''
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            getFiles(){
                this.setLoading(true);
                orderService.getFiles(this.orderDetailId).then((response) => {
                    if(response.statusCode == 200){
                        if(response.data !== null) {
                            this.files = response.data;
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getOrderFiles(){
                this.setLoading(true);
                orderService.getOrderFiles(this.orderId).then((response) => {
                    if(response.statusCode == 200){
                        if(response.data !== null) {
                            this.orderFiles = response.data;
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            view(obj){
                const link = document.createElement('a')
                link.href = obj.fullPath;
                link.target = '_blank';
                link.click()
                URL.revokeObjectURL(link.href)
            },

            open(orderId, orderDetailId, serial){
                this.title = "File đính kèm cont/kiện hàng " +serial;
                this.orderId = orderId;
                this.orderDetailId = orderDetailId;
                this.showDialog = true;
                this.files = [];
                this.getFiles();
                this.getOrderFiles();
            },

            close(){
                this.showDialog = false;
            }
        }
    })
</script>


<style lang="css" scoped>
    .md-content {
        max-width: 900px;
        width: 900px;
        min-height: 500px;
        max-height: 90vh;
    }
</style>
